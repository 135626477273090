import React, {useEffect} from 'react'
import {navigate} from 'gatsby'
import Layout from '../components/layout'
import '../styles/payment-receipt.css'

const PaymentReceipt = () => {

    const queryString = global.window?.location.search
    const urlParams = new URLSearchParams(queryString);
    const date = new Date().toDateString().split(' ').slice(1).join(' ');
    const txnId = urlParams.get('txnId') ? urlParams.get('txnId') : null
    const course = urlParams.get('course') ? urlParams.get('course') : null
    const amount = urlParams.get('amount') ? urlParams.get('amount') : null
    const name = urlParams.get('name') ? urlParams.get('name') : null

    useEffect(() => {
        if(!txnId){
            navigate('/404/');
        }
    }, [])

    return (
        <React.Fragment>
            <Layout title="Payment receipt | Crampete">
                <section className="payment-receipt-section">
                    <div className="container">
                        <div className="payment-receipt-card-wrapper">
                            <div className="payment-receipt-card">
                                <h1 className="text-center">Payment Confirmation</h1>
                                <p className="text-center">Thank you for your order {name &&<strong>{name}</strong>}!</p>
                                <p className="payment-receipt-card-receipt-no"><strong>Receipt No: {txnId}</strong></p>
                                <table className="table table-bordered text-center table-hover">
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Course</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{date}</td>
                                            <td>{course}</td>
                                            <td>&#8377; {amount}/-</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><strong>Total Paid</strong></td>
                                            <td><strong>&#8377; {amount}/-</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="text-center mb-0">
                                    <strong className="d-block">Questions or Concerns?</strong>
                                    <small>Send us an email [at] chennai@crampete.com or give us a call [at] +91 9384058989</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </React.Fragment>
    )
}

export default PaymentReceipt
